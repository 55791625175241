import React, { useEffect } from 'react';
import DefaultLayout from "../components/layouts/default"
import Welcome from "../components/home/welcome"
import BigCards from "../components/home/big-cards"
import Value from "../components/home/value"
import Process from "../components/home/process"
import Testimonials from "../components/home/testimonials"
import Pricing from "../components/home/pricing"
import Contact from "../components/home/contact"
import { SEO } from '../components'
import website from '../../config/website'
import JSONData from "../data/texts.json"
import agent from "../lib/agent"

// markup
const Home = () => {
  useEffect(() => {
    agent.Account.auth()
      .then(() => {
        window.location.href = "/app"
      })
      .catch(() => {
        window.localStorage.removeItem("jwt");
      })
  }, []);

  const isBrowser = typeof window !== "undefined"
  const pathName = isBrowser ? window.location.pathname: ""
  
  const main = JSONData.routes.main

  return (
    <main>
      <SEO title={website.titleAlt} pathname={pathName} />
      <DefaultLayout menu={JSONData.menu}>
        <Welcome view={main.header}/>
        <BigCards cards={main.big_cards}/>
        <Value view={main.value}/>
        <Process view={main.process}/>
        <Testimonials view={main.testimonials}/>
        <Pricing view={main.pricing}/>
        <Contact view={main.contact}/>
      </DefaultLayout>
    </main>
  )
}

export default Home
